import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class SettingService {
    getParamLists() {
        return instance.get("/api/param/index", {
            headers: authHeader(),
        });
    }

    updateParam(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("id", request.id);
        data.append("key", request.key);
        data.append("value", request.value);
        data.append("status", request.status);
        return instance.post("/api/param/update", data, {
            headers: authHeader(),
        });
    }

    createParam(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("key", request.key);
        data.append("value", request.value);
        return instance.post("/api/param/create", data, {
            headers: authHeader(),
        });
    }

    getBalanceTypeLists() {
        return instance.get("/api/balance-type/index", {
            headers: authHeader(),
        });
    }

    updateBalanceType(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("id", request.id);
        data.append("name", request.name);
        data.append("name_en", request.name_en);
        data.append("wallet_name", request.wallet_name);
        data.append("wallet_name_en", request.wallet_name_en);
        data.append("key", request.key);
        data.append("type", +request.type);
        if (request.img) {
            data.append("img", request.img);
        }
        data.append("bonus", +request.bonus);
        data.append("description", request.description);
        data.append("status", +request.status);
        data.append("order", request.order);
        data.append("is_play_for_fun", +request.is_play_for_fun);
        return instance.post("/api/balance-type/update", data, {
            headers: authHeader(),
        });
    }

    createBalanceType(request) {
        const FormData = require("form-data");
        const data = new FormData();
        console.log('request', request)
        data.append("name", request.name);
        data.append("name_en", request.name_en);
        data.append("wallet_name", request.wallet_name);
        data.append("wallet_name_en", request.wallet_name_en);
        data.append("key", request.key);
        data.append("type", +request.type);
        if (request.img) {
            data.append("img", request.img);
        }
        data.append("bonus", +request.bonus);
        data.append("description", request.description);
        data.append("status", +request.status);
        data.append("order", +request.order);
        data.append("is_play_for_fun", +request.is_play_for_fun);
        return instance.post("/api/balance-type/create", data, {
            headers: authHeader(),
        });
    }

    getThirdPartnerLists() {
        return instance.get("/api/third-partner/index", {
            headers: authHeader(),
        });
    }

    updateThirdPartner(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("id", request.id);
        data.append("name", request.name);
        data.append("code", request.code);
        if (request.balance_type_id) {
            data.append("balance_type_id", request.balance_type_id);
        }
        data.append("type", +request.type);
        if (request.game_category_id) {
            data.append("game_category_id", request.game_category_id);
        }
        data.append("status", request.status);
        data.append("is_new", request.is_new);
        if (request.start_maintenance) {
            data.append("start_maintenance", request.start_maintenance);
        }
        if (request.end_maintenance) {
            data.append("end_maintenance", request.end_maintenance);
        }
        if (request.img) {
            data.append("logo", request.logo);
        }
        return instance.post("/api/third-partner/update", data, {
            headers: authHeader(),
        });
    }

    createThirdPartner(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("name", request.name);
        data.append("code", request.code);
        if (request.balance_type_id) {
            data.append("balance_type_id", request.balance_type_id);
        }
        data.append("type", +request.type);
        if (request.game_category_id) {
            data.append("game_category_id", request.game_category_id);
        }
        data.append("status", request.status);
        data.append("is_new", request.is_new);
        if (request.start_maintenance) {
            data.append("start_maintenance", request.start_maintenance);
        }
        if (request.end_maintenance) {
            data.append("end_maintenance", request.end_maintenance);
        }
        if (request.img) {
            data.append("logo", request.logo);
        }
        return instance.post("/api/third-partner/create", data, {
            headers: authHeader(),
        });
    }

    getLevelRankLists() {
        return instance.get("/api/level-rank/index", {
            headers: authHeader(),
        });
    }

    getLevelLists() {
        return instance.get("/api/Level/index", {
            headers: authHeader(),
        });
    }

    getRankLists() {
        return instance.get("/api/rank/index", {
            headers: authHeader(),
        });
    }

    updateLevelRank(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("id", request.id);
        data.append("level_id", request.level_id);
        data.append("rank_id", request.rank_id);
        data.append("min_bet", +request.min_bet);
        data.append("min_deposit", +request.min_deposit);
        return instance.post("/api/level-rank/update", data, {
            headers: authHeader(),
        });
    }

    createLevelRank(request) {
        const FormData = require("form-data");
        const data = new FormData();
        data.append("level_id", request.level_id);
        data.append("rank_id", request.rank_id);
        data.append("min_bet", +request.min_bet);
        data.append("min_deposit", +request.min_deposit);
        return instance.post("/api/level-rank/create", data, {
            headers: authHeader(),
        });
    }

}

export default new SettingService();
