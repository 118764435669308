<template>
  <div>
    <b-modal id="modal-add-new-level-rank" cancel-variant="outline-secondary" :ok-title="$t('Add')" :cancel-title="$t('Close')"
      ok-variant="success" centered modal-class="modal-success" :title="$t('Add A New Param')" @ok="confirmAdd"
      @hidden="resetModal()">
      <!-- form -->
      <validation-observer ref="addNewLevelRankModalRef">
        <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
          <b-form-group label-for="level">
            <label class="mb-1">{{ $t('Level') }}</label>
            <validation-provider #default="{ errors }" name="level">
              <v-select v-model="newParam.level_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="levelList" :reduce="val => val.id" label="name"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="rank">
            <label class="mb-1">{{ $t('Rank') }}</label>
            <validation-provider #default="{ errors }" name="rank">
              <v-select v-model="newParam.rank_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rankList" :reduce="val => val.id" label="name"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="min_bet">
            <label class="mb-1">{{ $t("Min Bet") }}</label>
            <validation-provider #default="{ errors }" name="min_bet" rules="required">
              <b-form-input id="min_bet" v-model="newParam.min_bet" rows="4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="min_deposit">
            <label class="mb-1">{{ $t("Min Deposit") }}</label>
            <validation-provider #default="{ errors }" name="min_deposit" rules="required">
              <b-form-input id="min_deposit" v-model="newParam.min_deposit" rows="4"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    levelList: {
      type: Array,
      default: [],
    },
    rankList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      newParam: {
        level_id: "",
        rank_id: "",
        min_bet: "",
        min_deposit: "",
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.addNewLevelRankModalRef.validate().then((success) => {
        if (success) {
          this.addNewParam(this.newParam);
        }
      });
    },
    async addNewParam(newParam) {
      await this.$store
        .dispatch("setting/addLevelRank", newParam)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-add-new-level-rank");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.newParam = {
        level_id: "",
        rank_id: "",
        min_bet: "",
        min_deposit: "",
      };
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {};
  },
};
</script>