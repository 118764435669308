import SettingService from "@/libs/setting.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchParams() {
      return new Promise((resolve, reject) => {
        SettingService.getParamLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateParam(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.updateParam(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addParam(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.createParam(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBalanceType() {
      return new Promise((resolve, reject) => {
        SettingService.getBalanceTypeLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateBalanceType(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.updateBalanceType(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addBalanceType(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.createBalanceType(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchThirdPartner() {
      return new Promise((resolve, reject) => {
        SettingService.getThirdPartnerLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateThirdPartner(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.updateThirdPartner(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addThirdPartner(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.createThirdPartner(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLevels() {
      return new Promise((resolve, reject) => {
        SettingService.getLevelLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRanks() {
      return new Promise((resolve, reject) => {
        SettingService.getRankLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLevelRanks() {
      return new Promise((resolve, reject) => {
        SettingService.getLevelRankLists()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateLevelRank(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.updateLevelRank(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addLevelRank(ctx, data) {
      return new Promise((resolve, reject) => {
        SettingService.createLevelRank(data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
