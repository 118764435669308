var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-level-rank","cancel-variant":"outline-secondary","ok-title":_vm.$t('Edit'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Update A Level Rank')},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updateParamModalRef"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"level"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Level')))]),_c('validation-provider',{attrs:{"name":"level"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.levelList,"reduce":function (val) { return val.id; },"label":"name"},model:{value:(_vm.newParam.level_id),callback:function ($$v) {_vm.$set(_vm.newParam, "level_id", $$v)},expression:"newParam.level_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"rank"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t('Rank')))]),_c('validation-provider',{attrs:{"name":"rank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.rankList,"reduce":function (val) { return val.id; },"label":"name"},model:{value:(_vm.newParam.rank_id),callback:function ($$v) {_vm.$set(_vm.newParam, "rank_id", $$v)},expression:"newParam.rank_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"min_bet"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Min Bet")))]),_c('validation-provider',{attrs:{"name":"min_bet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"min_bet","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newParam.min_bet),callback:function ($$v) {_vm.$set(_vm.newParam, "min_bet", $$v)},expression:"newParam.min_bet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"min_deposit"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Min Deposit")))]),_c('validation-provider',{attrs:{"name":"min_deposit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"min_deposit","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newParam.min_deposit),callback:function ($$v) {_vm.$set(_vm.newParam, "min_deposit", $$v)},expression:"newParam.min_deposit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }