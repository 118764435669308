<template>
    <div>
        <div class="p-1">
            <b-row>
                <b-col v-if="$can('create', 'levelrank')" class="col d-flex justify-content-end">
                    <b-button variant="primary" @click="addNewTitle">
                        <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <b-card no-body>
            <b-table ref="refLevelRankListTable" class="position-relative table-white-space mb-0 max-height-table" sticky-header
                head-variant="light" :items="levelRankList" responsive :fields="tableColumns" primary-key="user_id"
                :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

                <template #cell(level_id)="data">
                    {{ data.item.levels.name}}
                </template>

                <template #cell(rank_id)="data">
                    {{ data.item.ranks.name }}
                </template>

                <template #cell(created_at)="data">
                    {{ data.item.created_at ? formatDateTime(data.item.created_at).replace(".000000Z", "") : '' }}
                </template>

                <template #cell(updated_at)="data">
                    {{ data.item.updated_at ? formatDateTime(data.item.updated_at).replace(".000000Z", "") : '' }}
                </template>

                <!-- Column: Actions -->
                <template #cell(action)="data">
                    <b-button v-b-tooltip.hover.v-info :title="$t('Edit')" v-if="$can('update', 'levelrank')" variant="primary"
                        class="btn-icon btn-sm mr-50" @click="updateNewTitle(data.item)">
                        <feather-icon icon="EditIcon" />
                    </b-button>
                </template>
            </b-table>
            <div class="p-2">
                <b-row>
                    <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
                        <span class="text-muted">Showing {{ dataMeta.of }} entries</span>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Add level rank Modal -->
        <AddNewLevelRankModal @refetch-data="refetchData" :levelList.sync="levelList" :rankList.sync="rankList"/>

        <!-- Update level rank Modal -->
        <UpdateLevelRankModal @refetch-data="refetchData" :levelRankDetail.sync="levelRankDetail" :levelList.sync="levelList" :rankList.sync="rankList"/>

    </div>
</template>
  
<script>
import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
    VBTooltip
} from "bootstrap-vue";
import i18n from "@/libs/i18n";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import settingStoreModule from "@/views/local-setting/setting/settingStoreModule";
import AddNewLevelRankModal from "@/views/local-setting/setting/level-rank/AddNewLevelRankModal.vue";
import UpdateLevelRankModal from "@/views/local-setting/setting/level-rank/UpdateLevelRankModal.vue";


export default {
    components: {
        AddNewLevelRankModal,
        UpdateLevelRankModal,
        flatPickr,
        BButton,
        BFormGroup,
        BModal,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BTable,
        BTr,
        BTh,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BImg,
        VBTooltip
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            levelRankDetail: null,
        };
    },
    methods: {
        addNewTitle() {
            this.$bvModal.show('modal-add-new-level-rank')
        },
        updateNewTitle(data) {
            this.levelRankDetail = data;
            this.$bvModal.show('modal-update-level-rank')
        },
    },
    setup() {
        if (!store.hasModule("setting"))
            store.registerModule("setting", settingStoreModule);
        onUnmounted(() => {
            if (store.hasModule("setting")) store.unregisterModule("setting");
        });

        const codeProduct = ref([]);
        const perPage = ref(25);
        const totalParams = ref(0);
        const currentPage = ref();
        const sortBy = ref("id");
        const isSortDirDesc = ref(false);

        const refLevelRankListTable = ref(null);
        const tableColumns = [
            { key: "index", label: "#", sortable: false },
            { key: "level_id", label: "Level", sortable: false },
            { key: "rank_id", label: "Rank", sortable: false },
            { key: "min_bet", label: "Min Bet", sortable: false },
            { key: "min_deposit", label: "Min Deposit", sortable: false },
            { key: "created_at", label: "Created at", sortable: false },
            { key: "updated_at", label: "Updated at", sortable: false },
            { key: "action", label: i18n.t("Actions") },
        ];

        const refetchData = () => {
            refLevelRankListTable.value.refresh();
            getListLevelRanks();
        };

        watch(
            [currentPage],
            () => {
                refetchData();
            }
        );
        const levelRankList = ref([]);
        const rankList = ref([]);
        const levelList = ref([]);
        store
            .dispatch("setting/fetchRanks", {})
            .then((response) => {
                rankList.value = response.data.data;
            });

        store
            .dispatch("setting/fetchLevels", {})
            .then((response) => {
                levelList.value = response.data.data;
            });

        const getListLevelRanks = (ctx, callback) => {
            store
                .dispatch("setting/fetchLevelRanks", {})
                .then((response) => {
                    perPage.value = response.pageSize;
                    levelRankList.value = response.data.data.map((item, index) => {
                        return {
                            ...item,
                            index: index + 1
                        }
                    });
                    totalParams.value = response.data.count;
                });
        };
        getListLevelRanks();
        const dataMeta = computed(() => {
            return {
                of: totalParams.value,
            };
        });


        return {
            tableColumns,
            perPage,
            currentPage,
            totalParams,
            dataMeta,
            sortBy,
            isSortDirDesc,
            refLevelRankListTable,
            getListLevelRanks,
            refetchData,
            numberFormat,
            formatDateTime,
            baseUrl,
            levelRankList,
            rankList,
            levelList
        };
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  